import { FormInstance } from 'antd';
import { useState } from 'react';
import { TestTypeType } from 'src/__generated__/graphqlTypes';
import { mapTestToSubjects } from './mapTestToSubjects';

export const AutoSumTestTypeMapping = {
  // pick required `subjects` from mapTestToSubjects
  [TestTypeType.Sat]: { totalFieldName: 'Total', subjects: ['Evidence-based reading and writing', 'Math'] },
  // [TestTypeType.Ucat]: {
  //   totalFieldName: 'Total',
  //   subjects: mapTestToSubjects(TestTypeType.Ucat)
  //     .filter((s) => !s.isTotalField)
  //     .map((s) => s.name)
  //     .filter((s) => !s.includes('Situational Judgement')),
  // },
  [TestTypeType.Ssat]: { totalFieldName: 'Total', subjects: ['Verbal', 'Quantitative', 'Reading'] },
  [TestTypeType.Toefl]: { totalFieldName: 'Total', subjects: ['Reading', 'Listening', 'Speaking', 'Writing'] },
  [TestTypeType.Gre]: { totalFieldName: 'Total', subjects: ['Verbal Reasoning', 'Quantitative Reasoning'] },
  [TestTypeType.Mcat]: {
    totalFieldName: 'Total',
    subjects: mapTestToSubjects(TestTypeType.Mcat)
      .filter((s) => !s.isTotalField)
      .map((s) => s.name),
  },
  [TestTypeType.Psat]: { totalFieldName: 'Total', subjects: ['Evidence-based reading and writing', 'Math'] },
  [TestTypeType.ArchitectureTilTest]: {
    totalFieldName: 'Total',
    subjects: ['Section 1', 'Section 2', 'Section 3', 'Section 4', 'Section 5'],
  },
  [TestTypeType.Enem]: {
    totalFieldName: 'Total',
    subjects: ['Language', 'Mathematics', 'Natural Science', 'Writing', 'Humanities'],
  },
};

type AutoSumFunctionType = (
  testType: TestTypeType | undefined | null,
  isDisplayAtReport?: undefined | null | boolean,
) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAutoCalcTotal = (formRef: FormInstance<any>): [AutoSumFunctionType, () => void] => {
  const { getFieldValue, setFieldsValue } = formRef;
  const [totalField, setTotalField] = useState<string>();

  // Use this function in `onChange` handler to auto calculate total.
  // Currently this function only support numeric grades.
  const calcTotal: AutoSumFunctionType = (testType, isDisplayAtReport = false) => {
    if (!testType) return;
    if (!Object.keys(AutoSumTestTypeMapping).includes(testType)) return;
    const { totalFieldName, subjects } = AutoSumTestTypeMapping[testType as keyof typeof AutoSumTestTypeMapping];
    const total =
      subjects && subjects.some((s) => getFieldValue(s))
        ? subjects
            .reduce((acc, cur) => {
              const subjectScore = getFieldValue(cur);
              if (subjectScore) {
                return acc + (+subjectScore || 0);
              }
              return acc;
            }, 0)
            .toFixed(0)
        : 0;

    if (isDisplayAtReport) {
      setFieldsValue({
        [totalFieldName]: total ? `${total}` : '0',
      });
    } else {
      setFieldsValue({
        [totalFieldName]: total ? `${total}` : 'Auto Calculation',
      });
    }

    setTotalField(totalFieldName);
  };

  const clearTotal = () => {
    setFieldsValue({
      [totalField as string]: undefined,
    });
  };

  return [calcTotal, clearTotal];
};
