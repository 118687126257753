import { DateTime } from 'luxon';
import { CONTRACT_SUB_STATUS } from 'src/components/FloatButton/constants';

export type TemplateItem = { rbac: string[]; render: (variables: TemplateVariables) => JSX.Element };

export type Templates = { [key: string]: TemplateItem };

export interface TemplateVariables {
  category?: string;
  operator: { userId: string; firstName: string; lastName: string };
  mission?: { id: string; title: string; category?: string; startAt?: string; dueDate?: string };
  internalNote?: { id: string; content: string };
  actionItem?: { id: string; description: string; dueDate?: string };
  reflection?: { id: string; description: string; dueDate?: string };
  member: { userId: string; firstName: string; lastName: string };
  oldState?: string;
  newState?: string;
  change: { key?: string; original?: string; value: string; difference?: number };
  order?: {
    subject: {
      name: string;
    };
  };
  handover?: {
    userRelationShipType?: string;
    student: { userId: string; firstName: string; lastName: string };
    allocatedUser: { userId: string; firstName: string; lastName: string };
    removedUser: { userId: string; firstName: string; lastName: string };
    subjectName?: string;
    academicAdvisorUser?: {
      userId: string;
      firstName: string;
      lastName: string;
    };
    approvedUser?: { userId: string; firstName: string; lastName: string };
  };
  programChange?: {
    operator: {
      userId: string;
      firstName: string;
      lastName: string;
    };
    subjectName?: string;
    changes?: {
      dateChange?: boolean;
      initValueChange?: boolean;
      remainingValueChange?: boolean;
      isUnlimited?: boolean;
      startDate?: {
        from: string;
        to: string;
      };
      assignedHours?: {
        from: number | string;
        to: number | string;
      };
      remainingHours?: {
        from: number | string;
        to: number | string;
      };
      comments?: string;
      action?: string;
      pause?: {
        from?: string;
        to?: string;
        context?: string;
      };
    };
  };
  tutorAllocation?: {
    student: { userId: string; firstName: string; lastName: string };
    subjectName?: string;
    contractCnt: number;
    autoSelection: boolean;
  };
  subContract?: {
    student: { userId: string; firstName: string; lastName: string };
    oldStatus?: string;
    newStatus: string;
  };
}

export const displayName = (user: { firstName: string; lastName: string }): string => {
  return user ? `${user.firstName} ${user.lastName}` : '[null]';
};

export const displayDescription = (desc?: string): string => {
  return desc && desc.length > 100 ? `${desc.slice(0, 100)}...` : desc || '';
};

export const displayMission = (mission?: { title: string }): string => {
  return mission ? mission.title : '[null]';
};

export const displayBadge = (badgeLevel?: string): string => {
  if (!badgeLevel) return '';
  badgeLevel = badgeLevel.toLowerCase();
  return badgeLevel[0].toUpperCase() + badgeLevel.substring(1);
};

export const displayDate = (date?: string): string => {
  if (date) {
    try {
      return DateTime.fromISO(date).toFormat('yyyy-MM-dd');
    } catch (e) {
      return `Date format error: [${date}]`;
    }
  }
  return '[null]';
};

export const displayCategory = (category?: string): string => {
  const categories: { [key: string]: string } = {
    ACADEMICS: 'Academics',
    ACTIVITIES: 'Activities',
    COMPETITIONS_HONORS: 'Honors',
    PERSONAL_DEVELOPMENT: 'Personal Development',
    CAREERS: 'Majors & Careers',
    TEST: 'Testing',
  };
  return category ? categories[category] || '[null]' : '[null]';
};

export const displayStatus = (status?: string): string => {
  const statuses: { [key: string]: string } = {
    PLANNED: 'Planned',
    IN_PROGRESS: 'In Progress',
    DONE: 'Completed',
    EXPIRED: 'Expired',
    POSTPONED: 'Postponed',
  };
  return status ? statuses[status] || '[null]' : '[null]';
};

export const hasTemplate = (templates: Templates, { code }: { code: string }): boolean => {
  return !!templates[code];
};

export const rbacCanAccess = (templates: Templates, { roles, code }: { roles?: string[]; code: string }): boolean => {
  const template = templates[code];

  if (template.rbac.includes('*')) {
    return true;
  }

  return (roles || []).some((role) => template.rbac.includes(role.toLowerCase()));
};

export const applyTemplate = (
  templates: Templates,
  { code, values }: { code: string; values: unknown },
): JSX.Element => {
  const template = templates[code];
  if (typeof values === 'string') {
    values = JSON.parse(values) as TemplateVariables;
  }

  if (!!template) {
    return template.render(values as TemplateVariables);
  }

  return <span>code unsupported: {code}</span>;
};

export const displaySubStatus = (subStatus: string): string => {
  return CONTRACT_SUB_STATUS.find((s) => s.value === subStatus)?.label || '';
};
