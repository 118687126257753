import React from 'react';
import * as Logger from '@crimson-education/browser-logger';
export function ErrorBoundryWrapper<Props>(
  Component: React.FC<Props>,
  doReport = true,
  meta = {},
): React.ComponentClass<Props> {
  const a = class wrapped extends React.Component<Props> {
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
      const { message, stack } = error;
      Logger.reportError(error, {
        ...meta,
      });
      if (doReport) {
        window?.xprops?.onMessage?.({
          event: 'error',
          data: JSON.stringify({
            message,
            stack,
            errorInfo,
            meta,
          }),
        });
      }
    }
    render(): React.ReactNode {
      const props = this.props;
      return <Component {...props} />;
    }
  };
  return a;
}
