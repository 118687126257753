import queryString from 'query-string';
const getLaunchOptions = (() => {
  try {
    const {
      userId: _userId = '',
      token: _token = '',
      launchMode = '',
    } = queryString.parse(window.location.search) as {
      token?: string;
      userId?: string;
      launchMode?: string;
    };
    const ___ = {
      token: _token,
      userId: _userId,
      launchMode,
    };
    return () => {
      return ___;
    };
  } catch (e) {
    return () => {
      return {
        token: '',
        userId: '',
        launchMode: '',
      };
    };
  }
})();

export { getLaunchOptions };
