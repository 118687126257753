export const VIP_OPTIONS = [
  {
    label: 'VIP',
    value: 'vip',
  },
  {
    label: 'Regular',
    value: 'regular',
  },
];

export const OPEN_ESCALATION_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const CURRENT_YEAR = 2023;
const MAX_YEAR = CURRENT_YEAR + 10;
const MIN_YEAR = CURRENT_YEAR - 1;
const years = [...Array(MAX_YEAR - MIN_YEAR)];

export const APP_CYCLE_OPTIONS = years.map((_, index) => {
  const appCycle = `${MAX_YEAR - index}/${MAX_YEAR - index + 1}`;
  return {
    label: appCycle,
    value: appCycle,
  };
});

export const CONTRACT_SUB_STATUS = [
  { label: 'Onboarding', value: 'onboarding' },
  { label: 'Active', value: 'active' },
  { label: 'Close Out', value: 'close_out' },
  { label: 'Paused (Service Paused)', value: 'paused_service_pause' },
  { label: 'Paused (Outstanding Payment)', value: 'paused_outstanding_payments' },
  { label: 'Alumni', value: 'alumni' },
  { label: 'Non-Alumni', value: 'non-alumni' },
  { label: 'Cancelled', value: 'cancelled' },
];

export type FilterType = {
  text?: string;
  studentType?: string;
  billingRegions?: string[];
  serviceCategories?: string[];
  intendedMajors?: string[];
  appCycles?: string[];
  appRounds?: string[];
  subjects?: string[];
  contractSubStatus?: string[];
  parentPreferredLanguage?: string[];
  openEscalation?: string[];
};

export type Subject = {
  id: string;
  name: string;
};

export const APPLICATION_ROUND_MAP: { [key: string]: string } = {
  'ED I': 'ED',
  'ED II': 'ED II',
  REA: 'REA',
  EA: 'EA',
  'EA II': 'EA II',
  Priority: 'Priority',
  RD: 'RD',
  Rolling: 'Rolling',
  Transfer: 'Transfer',
  'UK 1': 'UK 1 (Oct Deadline)',
  'UK 2': 'UK 2 (Jan Deadline)',
  Clearing: 'Clearing',
  Extra: 'Extra',
  Medicine: 'Medicine',
  'Foundation (UCAS)': 'Foundation (UCAS)',
  'Foundation (Non-UCAS)': 'Foundation (Non-UCAS)',
  'Undergraduate (Non-UCAS)': 'Undergraduate (Non-UCAS)',
  Undergraduate: 'Undergraduate',
  Postgraduate: 'Postgraduate',
  Foundation: 'Foundation',
  Other: 'Other',
};

export const APPLICATION_ROUND_OPTIONS = Object.keys(APPLICATION_ROUND_MAP).map((key: string) => ({
  label: key,
  value: APPLICATION_ROUND_MAP[key],
}));
