import { InternalNotesCategory, InternalNotesStatus } from 'src/__generated__/graphqlTypes';

export enum EVENT_LOG_TYPE {
  TEAM_HANDOVER = 'TEAM_HANDOVER',
  PROGRAM_CHANGE = 'PROGRAM_CHANGE',
  MISSION_NOTES = 'MISSION_NOTES',
  CONTRACT_CHANGE = 'CONTRACT_CHANGE',
  ALL = 'ALL',
}

export const CATEGORY_MAP_LOG_CODE = {
  [EVENT_LOG_TYPE.TEAM_HANDOVER]: [
    'ACTIVITY_TOPIC_TEAM_HANDOVER_CREATED',
    'ACTIVITY_TOPIC_TEAM_HANDOVER_REMOVED',
    'ACTIVITY_TOPIC_TEAM_HANDOVER_UPDATED',
    'ACTIVITY_TOPIC_TUTOR_ALLOCATION_CREATED',
    'ACTIVITY_TOPIC_STRATEGIST_PREALLOCATION_CREATED',
  ],
  [EVENT_LOG_TYPE.PROGRAM_CHANGE]: [
    'ACTIVITY_TOPIC_IN_APP_PURCHASE_ORDER_CREATED',
    'ACTIVITY_TOPIC_IN_APP_PURCHASE_ORDER_PAID',
    'ACTIVITY_TOPIC_PROGRAM_CHANGE_CREATED',
    'ACTIVITY_TOPIC_PROGRAM_CHANGE_REMOVED',
    'ACTIVITY_TOPIC_PROGRAM_CHANGE_UPDATED',
  ],
  [EVENT_LOG_TYPE.MISSION_NOTES]: ['MISSION_INTERNAL_NOTE_CREATE', 'MISSION_INTERNAL_NOTE_DELETE'],
  [EVENT_LOG_TYPE.CONTRACT_CHANGE]: ['ACTIVITY_TOPIC_SUB_CONTRACT_UPDATED'],
};
export const INTERNAL_NOTES_CATEGORY_TO_TEXT = {
  [InternalNotesCategory.Escalations]: 'escalation',
  [InternalNotesCategory.General]: 'general note',
  [InternalNotesCategory.Handover]: 'handover note',
  [InternalNotesCategory.Studentcheck]: 'student check',
  [InternalNotesCategory.Counselingfollowup]: 'counseling follow-up note',
  [InternalNotesCategory.Tutoringfollowup]: 'tutoring follow-up note',
  [InternalNotesCategory.Reminder]: 'reminder',
  [InternalNotesCategory.Payment]: 'payment note',
  [InternalNotesCategory.Customerservice]: 'customer service note',
  [InternalNotesCategory.Sales]: 'sales note',
  [InternalNotesCategory.Essays]: 'essays note',
};
export const EVENT_TYPE_OPTIONS = [
  {
    label: 'All',
    value: EVENT_LOG_TYPE.ALL,
  },
  {
    label: 'Escalations',
    value: InternalNotesCategory.Escalations,
  },
  {
    label: 'Program change',
    value: EVENT_LOG_TYPE.PROGRAM_CHANGE,
  },
  {
    label: 'Team handover',
    value: EVENT_LOG_TYPE.TEAM_HANDOVER,
  },
  {
    label: 'Mission notes',
    value: EVENT_LOG_TYPE.MISSION_NOTES,
  },
  {
    label: 'General notes',
    value: InternalNotesCategory.General,
  },
  {
    label: 'Student check',
    value: InternalNotesCategory.Studentcheck,
  },
];
export const TIME_PERIOD_OPTIONS = [
  {
    label: 'Last 1 week',
    value: 7,
  },
  {
    label: 'Last 1 month',
    value: 30,
  },
  {
    label: 'Last 2 months',
    value: 60,
  },
  {
    label: 'Last 3 months',
    value: 90,
  },
  {
    label: 'Last 6 months',
    value: 180,
  },
  {
    label: 'All',
    value: null,
  },
];

export const NOTE_INPUT_OPTIONS = [
  {
    value: InternalNotesCategory.General,
    label: 'General',
  },
  {
    value: InternalNotesCategory.Escalations,
    label: 'Escalations',
    children: [
      {
        value: 'Lack of progress',
        label: 'Lack of progress',
      },
      {
        value: 'Service team professionalism',
        label: 'Service team professionalism',
      },
      {
        value: 'Service team mistakes',
        label: 'Service team mistakes',
      },
      {
        value: 'Delay (allocation, responsiveness, start dates)',
        label: 'Delay (allocation, responsiveness, start dates)',
      },
      {
        value: "Doesn't like team member",
        label: "Doesn't like team member",
      },
      {
        value: 'Unclear roadmap',
        label: 'Unclear roadmap',
      },
      {
        value: 'Insufficient task follow-up',
        label: 'Insufficient task follow-up',
      },
      {
        value: 'Meeting frequency (strategy)',
        label: 'Meeting frequency (strategy)',
      },
      {
        value: "Service doesn't meet expectations",
        label: "Service doesn't meet expectations",
      },
      {
        value: 'Payment issue(delay, financial difficulties, debt collection)',
        label: 'Payment issue(delay, financial difficulties, debt collection)',
      },
      {
        value: 'Student engagement (off track, MIA, unresponsive)',
        label: 'Student engagement (off track, MIA, unresponsive)',
      },
      {
        value: 'Sales misalignment',
        label: 'Sales misalignment',
      },
      {
        value: 'Detractor NPS score',
        label: 'Detractor NPS score',
      },
      {
        value: 'General feedback',
        label: 'General feedback',
      },
      {
        value: 'None of the above',
        label: 'None of the above',
      },
    ],
  },
  {
    value: InternalNotesCategory.Handover,
    label: 'Handover',
  },
  {
    value: InternalNotesCategory.Studentcheck,
    label: 'Student check',
  },
  {
    value: InternalNotesCategory.Essays,
    label: 'Essays',
  },
];
export const CW_INTERNAL_NOTES_CATEGORY = [
  {
    value: InternalNotesCategory.Counselingfollowup,
    label: 'Counseling Follow-up',
  },
  {
    value: InternalNotesCategory.Tutoringfollowup,
    label: 'Tutoring Follow-up',
  },
  {
    value: InternalNotesCategory.Reminder,
    label: 'Reminder',
  },
  {
    value: InternalNotesCategory.Payment,
    label: 'Payment',
  },
  {
    value: InternalNotesCategory.Customerservice,
    label: 'Customer Service',
  },
  {
    value: InternalNotesCategory.Sales,
    label: 'Sales',
  },
  {
    value: InternalNotesCategory.Escalations,
    label: 'Escalation',
  },
  {
    value: InternalNotesCategory.Essays,
    label: 'Essays',
  },
];
export const STATUS_BUTTON_OPTIONS = [
  {
    label: 'Open',
    value: InternalNotesStatus.Open,
  },
  {
    label: 'Solved',
    value: InternalNotesStatus.Solved,
  },
  {
    label: 'Refund',
    value: InternalNotesStatus.Refund,
  },
  {
    label: 'Write off',
    value: InternalNotesStatus.WriteOff,
  },
  {
    label: 'Monitoring',
    value: InternalNotesStatus.Monitoring,
  },
];

export const INTERNAL_TASK_OPTIONS = [
  {
    label: 'Overdue payment',
    value: 'Overdue payment',
    dueDate: 5,
  },
  {
    label: 'Student Check',
    value: 'Student Check',
    dueDate: 5,
  },
  {
    label: 'Service paused students',
    value: 'Service paused students',
    dueDate: 7,
  },
  {
    label: 'Strategy off-track',
    value: 'Strategy off-track',
    dueDate: 2,
  },
  {
    label: 'No response in 48 hours in messages with tutors',
    value: 'No response in 48 hours in messages with tutors',
    dueDate: 1,
  },
  {
    label: 'Service paused students re-engagement',
    value: 'Service paused students re-engagement',
    dueDate: 7,
  },
  {
    label: 'Roadmap update',
    value: 'Roadmap update',
    dueDate: 1,
  },
  {
    label: 'Check-in call',
    value: 'Check-in call',
    dueDate: 1,
  },
  {
    label: 'AY-related tasks',
    value: 'AY-related tasks',
    dueDate: null,
  },
  {
    label: 'Utilization & Allocation',
    value: 'Utilization & Allocation',
    dueDate: 2,
    children: [
      {
        value: 'Low Session Effort',
        label: 'Low Session Effort',
      },
      {
        value: 'Low Session Score',
        label: 'Low Session Score',
      },
      {
        value: 'Missed/cancelled meetings',
        label: 'Missed/cancelled meetings',
      },
      {
        value: 'Reallocate',
        label: 'Reallocate',
      },
      {
        value: 'Remove tutors',
        label: 'Remove tutors',
      },
      {
        value: 'Roadblock',
        label: 'Roadblock',
      },
      {
        value: 'Schedule Recurring Meetings',
        label: 'Schedule Recurring Meetings',
      },
      {
        value: 'Transfer Hours',
        label: 'Transfer Hours',
      },
      {
        value: 'Trigger Allocation',
        label: 'Trigger Allocation',
      },
      {
        value: 'Tutoring off-track',
        label: 'Tutoring off-track',
      },
    ],
  },
  {
    label: 'MIA student follow up',
    value: 'MIA student follow up',
    dueDate: null,
  },
  {
    label: 'Escalation-related tasks',
    value: 'Escalation-related tasks',
    dueDate: null,
  },
  {
    label: 'Roadmap PDF',
    value: 'Roadmap_PDF',
  },
  {
    label: 'Other',
    value: 'Other',
    dueDate: null,
  },
];

export const FLAMINGO_INTERNAL_TASK_OPTIONS = [
  {
    label: 'Counseling Follow-up',
    value: 'Counseling Follow-up',
    dueDate: null,
  },
  {
    label: 'Tutoring Follow-up',
    value: 'Tutoring Follow-up',
    dueDate: null,
  },
  {
    label: 'Reminder',
    value: 'Reminder',
    dueDate: null,
  },
  {
    label: 'Payment',
    value: 'Payment',
    dueDate: null,
  },
  {
    label: 'Customer Service',
    value: 'Customer Service',
    dueDate: null,
  },
  {
    label: 'Sales',
    value: 'Sales',
    dueDate: null,
  },
  {
    label: 'Escalation',
    value: 'Escalation',
    dueDate: null,
  },
  {
    label: 'Essays',
    value: 'Essays',
    dueDate: null,
  },
];
